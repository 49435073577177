import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/LoginView.vue';
import Clients from '../views/ClientsView.vue';
import Projects from '../views/ProjectsView.vue';
import MonitoringPlans from '../views/MonitoringPlansView.vue';
import BackupPlans from '../views/BackupPlansView.vue';
import CloudPlans from '../views/CloudPlansView.vue';
import Project from '../views/ProjectView.vue';
import Deployments from '../views/DeploymentsView.vue';
import Repositories from '../views/RepositoriesView.vue';
import Dns from '../views/DnsView.vue';
import Cloud from '../views/CloudView.vue';
import Backups from '../views/BackupsView.vue';
import Monitoring from '../views/MonitoringView.vue';




const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'Login', component: Login },
  { path: '/projects', name: 'Projects', component: Projects },
  { path: '/clients', name: 'Clients', component: Clients },
  { path: '/monitoring_plans', name: 'Monitoring Plans', component: MonitoringPlans },
  { path: '/backup_plans', name: 'Backup Plans', component: BackupPlans },
  { path: '/cloud_plans', name: 'Cloud Plans', component: CloudPlans },
  { path: '/project/:id', name: 'Project', component: Project },
  { path: '/project/:id/deployments', name: 'Deployments', component: Deployments },
  { path: '/project/:id/repositories', name: 'Repositories', component: Repositories },
  { path: '/project/:id/dns', name: 'DNS', component: Dns },
  { path: '/project/:id/cloud', name: 'Cloud', component: Cloud },
  { path: '/project/:id/backups', name: 'Backups', component: Backups },
  { path: '/project/:id/monitoring', name: 'Monitoring', component: Monitoring },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
