<template>
  <GeneralLayout>
    <div class="flex flex-col">
      <div class="flex-row my-4 items-stretch">
        <h1 class="text-3xl font-bold float-start">Projects</h1>
        <button @click="showNewProjectPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">New Project</button>
        <input v-model="search" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
      </div>
      <div v-if="message" class="float-start flex-row rounded m-2" :class="messageClass">
        <p> {{ message }}</p>
      </div>
      <!-- Table -->
      <div class="overflow-x-auto">
          <table class="min-w-full bg-white border border-gray-200">
              <tbody class="text-gray-600 font-light min-w-full">
                  <tr v-for="project in filteredProject" :key="project.id" class="border-b border-gray-200 hover:bg-gray-100 group">
                    <td class="py-3 px-3 cursor-pointer" @click="this.$router.push('/project/'+project.id)" :title="project.name">
                      <img class="rounded float-start" :src="project.logo_path">
                      <div class="flex flex-col">
                        <p class="text-left text-xl float-start mx-3 my-1">{{project.client_name}}/{{project.name}}</p>
                        <p class="text-left text-sm float-start mx-3 my-1">{{project.description}}</p>
                      </div>
                    </td>
                    <td class="flex flex-row my-6 float-end mr-10">
                      <RouterLink :to="'/project/'+project.id+'/'+section.name" v-for="section in sections" :key="section.name" class="mx-1 hidden group-hover:block" :title="section.name">
                        <img class="rounded h-8" :src="'https://ui-avatars.com/api/?background='+section.color+'&color=fff&name='+section.name"  :alt="section.name"/>
                      </RouterLink>
                    </td>
                  </tr>
              </tbody>
          </table>
      </div>
      <PopupForm ref="newProjectForm" title="New Project" @form-submitted="createNewProject">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
          <input id="name" v-model="create_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="description" class="block text-sm font-medium text-gray-700 float-start">Description</label>
          <input id="description" v-model="create_form.description" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="client" class="block text-sm font-medium text-gray-700 float-start">Client</label>
          <select id="client" v-model="create_form.client_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="client in clients" :key="client.name" :label="client.name" :value="client.id">{{client.name}}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="backup_plan" class="block text-sm font-medium text-gray-700 float-start">Backup Plan</label>
          <select id="backup_plan" v-model="create_form.backup_plan_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="backup_plan in backup_plans" :key="backup_plan.name" :label="backup_plan.name" :value="backup_plan.id">{{backup_plan.name}}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="monitoring_plan" class="block text-sm font-medium text-gray-700 float-start">Monitoring Plan</label>
          <select id="monitoring_plan" v-model="create_form.monitoring_plan_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="monitoring_plan in monitoring_plans" :key="monitoring_plan.name" :label="monitoring_plan.name" :value="monitoring_plan.id">{{monitoring_plan.name}}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="cloud_plan" class="block text-sm font-medium text-gray-700 float-start">Cloud Plan</label>
          <select id="cloud_plan" v-model="create_form.cloud_plan_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="cloud_plan in cloud_plans" :key="cloud_plan.name" :label="cloud_plan.name" :value="cloud_plan.id">{{cloud_plan.name}}</option>
          </select>
        </div>

      </PopupForm>
    </div>
  </GeneralLayout>
</template>
  
<script>
import GeneralLayout from '@/layouts/GeneralLayout'
import PopupForm from '@/components/PopupForm.vue';
import { getProjects, createProject, getClients, getBackupPlans, getMonitoringPlans, getCloudPlans } from '../services/api';
  
export default {
  components: {
    GeneralLayout,
    PopupForm,
  },

  data() {
    return {
      create_form: {
        name: '',
        description: '',
        logo_path: '',
        client_id: '',
        monitoring_plan_id: '',
        backup_plan_id: '',
        cloud_plan_id: '',
      },
      projects: [],
      message: '',
      messageClass: '',
      search: '',
      clients: [],
      monitoring_plans: [],
      backup_plans: [],
      cloud_plans: [],
      sections: [
        {
          name: 'deployments',
          color: 'e74c3c'
        },
        // {
        //   name: 'repositories',
        //   color: '8e44ad'
        // },
        // {
        //   name: 'dns',
        //   color: '3498db'
        // },
        // {
        //   name: 'cloud',
        //   color: '16a085'
        // },
        // {
        //   name: 'backups',
        //   color: 'f1c40f'
        // },
        // {
        //   name: 'monitoring',
        //   color: 'd35400'
        // },
      ]      
    };
  },
  async mounted() {
    try {
      this.projects = await getProjects().then(res => res.data);
      this.clients = await getClients().then(res => res.data);
      this.monitoring_plans = await getMonitoringPlans().then(res => res.data);
      this.backup_plans = await getBackupPlans().then(res => res.data);
      this.cloud_plans = await getCloudPlans().then(res => res.data);

    }
    catch {
      // localStorage.removeItem('token');
      this.$router.push('/login');
    }
  },

  methods: {
    showNewProjectPopup() {
      this.$refs.newProjectForm.openForm();
    },
    async createNewProject() {
      try{
        const color = this.getRandomColor()
        this.create_form.logo_path = 'https://ui-avatars.com/api/?background='+color.bg+'&color='+color.text+'&name='+this.create_form.name
        const response = await createProject(this.create_form).then(res => res.data);
        if (response && response.message === 'projects created successfully') {
          
          this.projects = await getProjects().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Project created successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't create the project"
          setTimeout(() => this.message = '', 2000);
        }
        this.create_form = {
          name: '',
          description: ''
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't create the project"
        setTimeout(() => this.message = '', 2000);
      }
    },
    getRandomColor(){
      const color_list = [
        {
          'bg': 'c0392b',
          'text': 'fff'
        },
        {
          'bg': 'e74c3c',
          'text': 'fff'
        },
        {
          'bg': '9b59b6',
          'text': 'fff'
        },
        {
          'bg': '8e44ad',
          'text': 'fff'
        },
        {
          'bg': '5499c7',
          'text': 'fff'
        },
        {
          'bg': '3498db',
          'text': 'fff'
        },
        {
          'bg': '1abc9c',
          'text': 'fff'
        },
        {
          'bg': '16a085',
          'text': 'fff'
        },
        {
          'bg': '27ae60',
          'text': 'fff'
        },
        {
          'bg': '2ecc71',
          'text': 'fff'
        },
        {
          'bg': 'f1c40f',
          'text': 'fff'
        },
        {
          'bg': 'f39c12',
          'text': 'fff'
        },
        {
          'bg': 'e67e22',
          'text': 'fff'
        },
        {
          'bg': 'd35400',
          'text': 'fff'
        },
        {
          'bg': 'bdc3c7',
          'text': 'fff'
        },
        {
          'bg': '95a5a6',
          'text': 'fff'
        },
        {
          'bg': '7f8c8d',
          'text': 'fff'
        },
        {
          'bg': '2e4053',
          'text': 'fff'
        },
      ]
      const index = Math.floor(Math.random() * 17);
      return color_list[index]
    }
  },
  computed: {
    filteredProject(){
      return this.projects.filter(project => {
        return project.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
      })
    }
  }
  
};
</script>
  