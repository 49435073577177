<template>
  <ProjectLayout>
    <div class="flex flex-col">
      <div class="flex-row my-4 items-stretch">
        <h1 class="text-3xl font-bold float-start">Overview</h1>
      </div>
    </div>
  </ProjectLayout>
</template>
  
<script>
import ProjectLayout from '@/layouts/ProjectLayout'
  
export default {
  components: {
    ProjectLayout,
  },

  data() {
    return {
    };
  },
};
</script>
  