<template>
  <GeneralLayout>
    <div class="flex flex-col">
      <div class="flex-row my-4 items-stretch">
        <h1 class="text-3xl font-bold float-start">Monitoring Plans</h1>
        <button @click="showNewMonitoringPlanPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">New Monitoring Plan</button>
        <input v-model="search" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
      </div>
      <div v-if="message" class="float-start flex-row rounded m-2" :class="messageClass">
        <p> {{ message }}</p>
      </div>
      <!-- Table -->
      <div class="overflow-x-auto">
          <table class="min-w-full bg-white border border-gray-200">
              <!-- Table Head -->
              <thead>
                  <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                      <th class="py-3 px-6 text-center">Name</th>
                      <th class="py-3 px-6 text-center">Alerting</th>
                      <th class="py-3 px-6 text-center">Check URLs</th>
                      <th class="py-3 px-6 text-center">Metrics</th>
                      <th class="py-3 px-6 text-center">Logs</th>
                      <th class="py-3 px-6 text-center">Retention Days</th>
                      <th class="py-3 px-6 text-center">Assigned Projects</th>
                      <th class="py-3 px-6 text-center">Actions</th>
                  </tr>
              </thead>
              <tbody class="text-gray-600 text-sm font-light min-w-full">
                  <tr v-for="monitoring_plan in filteredMonitoringPlan" :key="monitoring_plan.id" class="border-b border-gray-200">
                      <td class="py-3 px-6 text-center ">{{monitoring_plan.name}}
                      </td>
                      <td class="py-3 px-6 text-center">{{ monitoring_plan.alerting }}</td>
                      <td class="py-3 px-6 text-center">{{ monitoring_plan.url_check }}</td>
                      <td class="py-3 px-6 text-center">{{ monitoring_plan.metrics }}</td>
                      <td class="py-3 px-6 text-center">{{ monitoring_plan.logs }}</td>
                      <td class="py-3 px-6 text-center">{{ monitoring_plan.retention_days }}</td>
                      <td class="py-3 px-6 text-center">{{ monitoring_plan.projects_count }}</td>
                      <td class="py-3 px-6 text-center">
                          <button @click="showEditMonitoringPlanPopup(monitoring_plan)" class="bg-white text-fuchsia-600 py-1 px-3 rounded hover:bg-gray-200 font-medium mr-2 border-solid border border-fuchsia-600">Edit</button>
                          <button @click="showDeleteMonitoringPlanPopup(monitoring_plan.id)" class="bg-fuchsia-700 text-white  py-1 px-3 rounded hover:bg-fuchsia-800 font-medium">Delete</button>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      <PopupForm ref="newMonitoringPlanForm" title="New MonitoringPlan" @form-submitted="createNewMonitoringPlan">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="create_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="alerting" class="block text-sm font-medium text-gray-700 float-start">Alerting</label>
          <input id="alerting" v-model="create_form.alerting" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="url_check" class="block text-sm font-medium text-gray-700 float-start">Url Checks</label>
          <input id="url_check" v-model="create_form.url_check" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="metrics" class="block text-sm font-medium text-gray-700 float-start">Metrics</label>
          <input id="metrics" v-model="create_form.metrics" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="logs" class="block text-sm font-medium text-gray-700 float-start">Logs</label>
          <input id="logs" v-model="create_form.logs" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="retention_days" class="block text-sm font-medium text-gray-700 float-start">Retention (Days)</label>
          <input id="retention_days" v-model="create_form.retention_days" type="number" min="1" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="editMonitoringPlanForm" title="Edit MonitoringPlan" @form-submitted="editMonitoringPlan(edit_form.id)">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="edit_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="alerting" class="block text-sm font-medium text-gray-700 float-start">Alerting</label>
          <input id="alerting" v-model="edit_form.alerting" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="url_check" class="block text-sm font-medium text-gray-700 float-start">Url Checks</label>
          <input id="url_check" v-model="edit_form.url_check" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="metrics" class="block text-sm font-medium text-gray-700 float-start">Metrics</label>
          <input id="metrics" v-model="edit_form.metrics" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="logs" class="block text-sm font-medium text-gray-700 float-start">Logs</label>
          <input id="logs" v-model="edit_form.logs" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="retention_days" class="block text-sm font-medium text-gray-700 float-start">Retention (Days)</label>
          <input id="retention_days" v-model="edit_form.retention_days" type="number" min="1" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="deleteMonitoringPlanForm" title="Delete MonitoringPlan" @form-submitted="delMonitoringPlan(delete_id)">
        <div class="mb-4">
          <p class="block text-sm font-medium text-gray-700 float-start">Are you sure do you want to delete it?</p>
        </div>
      </PopupForm>
    </div>
  </GeneralLayout>
</template>
  
<script>
import GeneralLayout from '@/layouts/GeneralLayout'
import PopupForm from '@/components/PopupForm.vue';
import { getMonitoringPlans, createMonitoringPlan, updateMonitoringPlan, deleteMonitoringPlan } from '../services/api';
  
export default {
  components: {
    GeneralLayout,
    PopupForm,
  },

  data() {
    return {
      create_form: {
        name: '',
        alerting: false,
        url_check: false,
        logs: false,
        metrics: false,
        retention_days_days: ''
      },
      edit_form: {
        name: '',
        alerting: false,
        url_check: false,
        logs: false,
        metrics: false,
        retention_days_days: ''
      },
      monitoring_plans: [],
      message: '',
      messageClass: '',
      search: '',
      delete_id: '',
      periodicity_options: [
        {
          name: 'Daily',
          value: 'daily'
        },
        {
          name: 'Weekly',
          value: 'weekly'
        },
        {
          name: 'Monthly',
          value: 'monthly'
        },
      ]
      
    };
  },
  async mounted() {
    try {
      this.monitoring_plans = await getMonitoringPlans().then(res => res.data);
      console.log(this.monitoring_plans)
    }
    catch {
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  },

  methods: {
    showNewMonitoringPlanPopup() {
      this.$refs.newMonitoringPlanForm.openForm();
    },
    showDeleteMonitoringPlanPopup(id) {
      this.delete_id = id
      this.$refs.deleteMonitoringPlanForm.openForm();
    },
    showEditMonitoringPlanPopup(monitoring_plan) {
      this.edit_form.id = monitoring_plan.id
      this.edit_form.name = monitoring_plan.name
      this.edit_form.periodicity = monitoring_plan.periodicity
      this.edit_form.retention_days = monitoring_plan.retention_days
      this.$refs.editMonitoringPlanForm.openForm();
    },
    async createNewMonitoringPlan() {
      try{
        const response = await createMonitoringPlan(this.create_form).then(res => res.data);
        if (response && response.message === 'monitoring_plans created successfully') {
          this.monitoring_plans = await getMonitoringPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'MonitoringPlan created successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't create the monitoring_plan"
          setTimeout(() => this.message = '', 2000);
        }
        this.create_form = {
          name: '',
          alerting: false,
          url_check: false,
          logs: false,
          metrics: false,
          retention_days_days: ''
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't create the monitoring_plan"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async editMonitoringPlan(id) {
      console.log(this.edit_form.periodicity)
      try{
        const response = await updateMonitoringPlan(id, this.edit_form).then(res => res.data);
        if (response && response.message.includes('edited successfully')) {
          this.monitoring_plans = await getMonitoringPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'MonitoringPlan edited successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't edit the monitoring_plan"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't edit the monitoring_plan"
        setTimeout(() => this.message = '', 2000);
      }
    },

    async delMonitoringPlan(id) {
      try{
        const response = await deleteMonitoringPlan(id).then(res => res.data);
        if (response && response.message.includes('deleted successfully')) {
          this.edit_form = {
            name: '',
            description: ''
          }
          this.monitoring_plans = await getMonitoringPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'MonitoringPlan deleted successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't delete the monitoring_plan"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't delete the monitoring_plan"
        setTimeout(() => this.message = '', 2000);
      }
    },
  },
  computed: {
    filteredMonitoringPlan(){
      return this.monitoring_plans.filter(monitoring_plan => {
        return monitoring_plan.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
      })
    }
  }
  
};
</script>
  