<template>
    <header>
            <nav class="bg-white ml-64 p-6">
            <div class="container mx-auto flex justify-between items-center">
                <RouterLink to="/" class="text-white text-2xl font-bold"><img class="max-h-20" src="@/assets/logo-robot.png"/></RouterLink>

                <div class="flex space-x-6">
                    <button class="text-black hover:text-fuchsia-700" @click="logout">Logout</button>
                </div>
            </div>
        </nav>
    </header>
    <main>
        <slot></slot>
    </main>
</template>

<script>
export default {
    methods: {
        logout(){
            localStorage.removeItem('token');
            this.$router.push('/login');
        }
    },
    beforeCreate() {
        if ( localStorage.getItem('token') === null ){
            this.$router.push('/login');
        }
    },
}
</script>