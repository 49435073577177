import axios from 'axios';

// Base API URL (replace with your actual API URL)
const api = axios.create({
  baseURL: process.env.baseURL,
  headers: {
    "Content-Type": "application/json"
  }
});

// Attach token to requests
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

// Authentication APIs
export const login = (data) => api.post('/login', data);
export const changePassword = (data) => api.post('/change-password', data);
export const refreshToken = (data) => api.post('/refresh', data);

// Clients CRUD
export const getClients = () => api.get('/clients');
export const createClient = (data) => api.post('/clients', data);
export const getClientById = (id) => api.get(`/clients/${id}`);
export const updateClient = (id, data) => api.put(`/clients/${id}`, data);
export const deleteClient = (id) => api.delete(`/clients/${id}`);

// Projects CRUD
export const getProjects = () => api.get('/projects');
export const createProject = (data) => api.post('/projects', data);
export const getProjectById = (id) => api.get(`/projects/${id}`);
export const updateProject = (id, data) => api.put(`/projects/${id}`, data);
export const deleteProject = (id) => api.delete(`/projects/${id}`);

// Deployments CRUD
export const getDeployments = () => api.get('/deployments');
export const createDeployment = (data) => api.post('/deployments', data);
export const getDeploymentById = (id) => api.get(`/deployments/${id}`);
export const updateDeployment = (id, data) => api.put(`/deployments/${id}`, data);
export const deleteDeployment = (id) => api.delete(`/deployments/${id}`);

// Monitoring Plans CRUD
export const getMonitoringPlans = () => api.get('/monitoring_plans');
export const createMonitoringPlan = (data) => api.post('/monitoring_plans', data);
export const getMonitoringPlanById = (id) => api.get(`/monitoring_plans/${id}`);
export const updateMonitoringPlan = (id, data) => api.put(`/monitoring_plans/${id}`, data);
export const deleteMonitoringPlan = (id) => api.delete(`/monitoring_plans/${id}`);

// Backup Plans CRUD
export const getBackupPlans = () => api.get('/backup_plans');
export const createBackupPlan = (data) => api.post('/backup_plans', data);
export const getBackupPlanById = (id) => api.get(`/backup_plans/${id}`);
export const updateBackupPlan = (id, data) => api.put(`/backup_plans/${id}`, data);
export const deleteBackupPlan = (id) => api.delete(`/backup_plans/${id}`);

// Cloud Plans CRUD
export const getCloudPlans = () => api.get('/cloud_plans');
export const createCloudPlan = (data) => api.post('/cloud_plans', data);
export const getCloudPlanById = (id) => api.get(`/cloud_plans/${id}`);
export const updateCloudPlan = (id, data) => api.put(`/cloud_plans/${id}`, data);
export const deleteCloudPlan = (id) => api.delete(`/cloud_plans/${id}`);