<template>
    <div class="grid justify-center items-center content-center h-screen">
      <img class="m" src="@/assets/logo.png" alt="Nordkat DevOps Logo"/>
      <form @submit.prevent="submitLogin">
        <input class="my-3 px-2 border-gray-300 border-solid border rounded" v-model="email" placeholder="Email" /><br/>
        <input class="my-3 px-2 border-gray-300 border-solid border rounded" v-model="password" type="password" placeholder="Password" /><br/>
        <button class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-7 text-white font-medium my-3 " type="submit">Login</button>
      </form>
      <p v-if="error">{{ error }}</p>
    </div>
  </template>
  
  <script>
  import { login } from '../services/api';
  
  export default {
    name: 'AutomationLogin',
    data() {
      return {
        email: '',
        password: '',
        error: '',
      };
    },
    mounted(){
      if ( localStorage.getItem('token') != null ){
        this.$router.push('/projects');
      }
    },
    methods: {
      async submitLogin() {
        try {
          const response = await login({ email: this.email, password: this.password });
          localStorage.setItem('token', response.data.access_token);
          this.$router.push('/projects'); // Redirect after login
        } catch (err) {
          this.error = err;
          // this.error = 'Invalid credentials';
        }
      },
    },
  };
  </script>
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
  </style>