<template>
  <ProjectLayout>
    <div class="flex flex-col">
      <div class="flex-row my-4 items-stretch">
        <h1 class="text-3xl font-bold float-start">Repositories</h1>
        <button @click="showNewClientPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">New Repository</button>
        <input v-model="search" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
      </div>
      <div v-if="message" class="float-start flex-row rounded m-2" :class="messageClass">
        <p> {{ message }}</p>
      </div>
      <!-- Table -->
      <div class="overflow-x-auto">
          <table class="min-w-full bg-white border border-gray-200">
              <!-- Table Head -->
              <thead>
                  <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                      <th class="py-3 px-6 text-center">Name</th>
                      <th class="py-3 px-6 text-center">Description</th>
                      <th class="py-3 px-6 text-center">Assigned Projects</th>
                      <th class="py-3 px-6 text-center">Actions</th>
                  </tr>
              </thead>
              <tbody class="text-gray-600 text-sm font-light min-w-full">
                  <tr v-for="client in filteredClient" :key="client.id" class="border-b border-gray-200">
                      <td class="py-3 px-6 text-center ">{{client.name}}
                      </td>
                      <td class="py-3 px-6 text-center">{{ client.description }}</td>
                      <td class="py-3 px-6 text-center">{{ client.projects_count }}</td>
                      <td class="py-3 px-6 text-center">
                          <button @click="showEditClientPopup(client)" class="bg-white text-fuchsia-600 py-1 px-3 rounded hover:bg-gray-200 font-medium mr-2 border-solid border border-fuchsia-600">Edit</button>
                          <button @click="showDeleteClientPopup(client.id)" class="bg-fuchsia-700 text-white  py-1 px-3 rounded hover:bg-fuchsia-800 font-medium">Delete</button>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      <PopupForm ref="newClientForm" title="New Client" @form-submitted="createNewClient">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="create_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="description" class="block text-sm font-medium text-gray-700 float-start">Description</label>
          <input id="description" v-model="create_form.description" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="editClientForm" title="Edit Client" @form-submitted="editClient(edit_form.id)">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="edit_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="description" class="block text-sm font-medium text-gray-700 float-start">Description</label>
          <input id="description" v-model="edit_form.description" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="deleteClientForm" title="Delete Client" @form-submitted="delClient(delete_id)">
        <div class="mb-4">
          <p class="block text-sm font-medium text-gray-700 float-start">Are you sure do you want to delete it?</p>
        </div>
      </PopupForm>
    </div>
  </ProjectLayout>
</template>
  
<script>
import ProjectLayout from '@/layouts/ProjectLayout'
import PopupForm from '@/components/PopupForm.vue';
import { getClients, createClient, updateClient, deleteClient } from '../services/api';
  
export default {
  components: {
    ProjectLayout,
    PopupForm,
  },

  data() {
    return {
      create_form: {
        name: '',
        description: ''
      },
      edit_form: {
        id: '',
        name: '',
        description: ''
      },
      clients: [],
      message: '',
      messageClass: '',
      search: '',
      delete_id: '',
    };
  },
  async mounted() {
    try {
      this.clients = await getClients().then(res => res.data);
    }
    catch {
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  },

  methods: {
    showNewClientPopup() {
      this.$refs.newClientForm.openForm();
    },
    showDeleteClientPopup(id) {
      this.delete_id = id
      this.$refs.deleteClientForm.openForm();
    },
    showEditClientPopup(client) {
      this.edit_form.id = client.id
      this.edit_form.name = client.name
      this.edit_form.description = client.description
      this.$refs.editClientForm.openForm();
    },
    async createNewClient() {
      try{
        const response = await createClient(this.create_form).then(res => res.data);
        if (response && response.message === 'clients created successfully') {
          
          this.clients = await getClients().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Client created successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't create the client"
          setTimeout(() => this.message = '', 2000);
        }
        this.create_form = {
          name: '',
          description: ''
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't create the client"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async editClient(id) {
      try{
        const response = await updateClient(id, this.edit_form).then(res => res.data);
        if (response && response.message.includes('edited successfully')) {
          this.clients = await getClients().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Client edited successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't edit the client"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't edit the client"
        setTimeout(() => this.message = '', 2000);
      }
    },

    async delClient(id) {
      try{
        const response = await deleteClient(id).then(res => res.data);
        if (response && response.message.includes('deleted successfully')) {
          this.edit_form = {
            name: '',
            description: ''
          }
          this.clients = await getClients().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Client deleted successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't delete the client"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't delete the client"
        setTimeout(() => this.message = '', 2000);
      }
    },
  },
  computed: {
    filteredClient(){
      return this.clients.filter(client => {
        return client.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
      })
    }
  }
  
};
</script>
  