<template>
  <GeneralLayout>
    <div class="flex flex-col">
      <div class="flex-row my-4 items-stretch">
        <h1 class="text-3xl font-bold float-start">Cloud Plans</h1>
        <button @click="showNewCloudPlanPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">New Cloud Plan</button>
        <input v-model="search" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
      </div>
      <div v-if="message" class="float-start flex-row rounded m-2" :class="messageClass">
        <p> {{ message }}</p>
      </div>
      <!-- Table -->
      <div class="overflow-x-auto">
          <table class="min-w-full bg-white border border-gray-200">
              <!-- Table Head -->
              <thead>
                  <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                      <th class="py-3 px-6 text-center">Name</th>
                      <th class="py-3 px-6 text-center">Periodicity</th>
                      <th class="py-3 px-6 text-center">Retention (Days)</th>
                      <th class="py-3 px-6 text-center">Assigned Projects</th>
                      <th class="py-3 px-6 text-center">Actions</th>
                  </tr>
              </thead>
              <tbody class="text-gray-600 text-sm font-light min-w-full">
                  <tr v-for="cloud_plan in filteredCloudPlan" :key="cloud_plan.id" class="border-b border-gray-200">
                      <td class="py-3 px-6 text-center ">{{cloud_plan.name}}
                      </td>
                      <td class="py-3 px-6 text-center">{{ cloud_plan.periodicity }}</td>
                      <td class="py-3 px-6 text-center">{{ cloud_plan.retention_days }}</td>
                      <td class="py-3 px-6 text-center">{{ cloud_plan.projects_count }}</td>
                      <td class="py-3 px-6 text-center">
                          <button @click="showEditCloudPlanPopup(cloud_plan)" class="bg-white text-fuchsia-600 py-1 px-3 rounded hover:bg-gray-200 font-medium mr-2 border-solid border border-fuchsia-600">Edit</button>
                          <button @click="showDeleteCloudPlanPopup(cloud_plan.id)" class="bg-fuchsia-700 text-white  py-1 px-3 rounded hover:bg-fuchsia-800 font-medium">Delete</button>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      <PopupForm ref="newCloudPlanForm" title="New CloudPlan" @form-submitted="createNewCloudPlan">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="create_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="periodicity" class="block text-sm font-medium text-gray-700 float-start">Periodicity</label><br/>
          <select v-model="create_form.periodicity" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="option in periodicity_options" :key="option.name" :label="option.name" :value="option.value">{{option.name}}</option>
          </select>

        </div>
        <div class="mb-4">
          <label for="retention_days" class="block text-sm font-medium text-gray-700 float-start">Retention (Days)</label>
          <input id="retention_days" v-model="create_form.retention_days" min="1" type="number" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="editCloudPlanForm" title="Edit CloudPlan" @form-submitted="editCloudPlan(edit_form.id)">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="edit_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="periodicity" class="block text-sm font-medium text-gray-700 float-start">Periodicity</label><br>
          <select v-model="edit_form.periodicity" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="option in periodicity_options" :key="option.name" :label="option.name" :value="option.value">{{option.name}}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="retention_days" class="block text-sm font-medium text-gray-700 float-start">Retention  (Days)</label>
          <input id="retention_days" v-model="edit_form.retention_days" min="1" type="number" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="deleteCloudPlanForm" title="Delete CloudPlan" @form-submitted="delCloudPlan(delete_id)">
        <div class="mb-4">
          <p class="block text-sm font-medium text-gray-700 float-start">Are you sure do you want to delete it?</p>
        </div>
      </PopupForm>
    </div>
  </GeneralLayout>
</template>
  
<script>
import GeneralLayout from '@/layouts/GeneralLayout'
import PopupForm from '@/components/PopupForm.vue';
import { getCloudPlans, createCloudPlan, updateCloudPlan, deleteCloudPlan } from '../services/api';
  
export default {
  components: {
    GeneralLayout,
    PopupForm,
  },

  data() {
    return {
      create_form: {
        name: '',
        periodicity: '',
        retention_days: ''
      },
      edit_form: {
        id: '',
        name: '',
        periodicity: '',
        retention_days: ''
      },
      cloud_plans: [],
      message: '',
      messageClass: '',
      search: '',
      delete_id: '',
      periodicity_options: [
        {
          name: 'Daily',
          value: 'daily'
        },
        {
          name: 'Weekly',
          value: 'weekly'
        },
        {
          name: 'Monthly',
          value: 'monthly'
        },
      ]
      
    };
  },
  async mounted() {
    try {
      this.cloud_plans = await getCloudPlans().then(res => res.data);
    }
    catch {
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  },

  methods: {
    showNewCloudPlanPopup() {
      this.$refs.newCloudPlanForm.openForm();
    },
    showDeleteCloudPlanPopup(id) {
      this.delete_id = id
      this.$refs.deleteCloudPlanForm.openForm();
    },
    showEditCloudPlanPopup(cloud_plan) {
      this.edit_form.id = cloud_plan.id
      this.edit_form.name = cloud_plan.name
      this.edit_form.periodicity = cloud_plan.periodicity
      this.edit_form.retention_days = cloud_plan.retention_days
      this.$refs.editCloudPlanForm.openForm();
    },
    async createNewCloudPlan() {
      try{
        const response = await createCloudPlan(this.create_form).then(res => res.data);
        if (response && response.message === 'cloud_plans created successfully') {
          this.cloud_plans = await getCloudPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'CloudPlan created successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't create the cloud_plan"
          setTimeout(() => this.message = '', 2000);
        }
        this.create_form = {
          name: '',
          periodicity: '',
          retention_days: ''
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't create the cloud_plan"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async editCloudPlan(id) {
      try{
        const response = await updateCloudPlan(id, this.edit_form).then(res => res.data);
        if (response && response.message.includes('edited successfully')) {
          this.cloud_plans = await getCloudPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'CloudPlan edited successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't edit the cloud_plan"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't edit the cloud_plan"
        setTimeout(() => this.message = '', 2000);
      }
    },

    async delCloudPlan(id) {
      try{
        const response = await deleteCloudPlan(id).then(res => res.data);
        if (response && response.message.includes('deleted successfully')) {
          this.edit_form = {
            name: '',
            description: ''
          }
          this.cloud_plans = await getCloudPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'CloudPlan deleted successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't delete the cloud_plan"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't delete the cloud_plan"
        setTimeout(() => this.message = '', 2000);
      }
    },
  },
  computed: {
    filteredCloudPlan(){
      return this.cloud_plans.filter(cloud_plan => {
        return cloud_plan.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
      })
    }
  }
  
};
</script>
  