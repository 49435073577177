<template>
  <ProjectLayout>
    <div class="flex flex-col">
      <div class="flex-row my-4 items-stretch">
        <h1 class="text-3xl font-bold float-start">Deployments</h1>
        <button @click="showNewDeploymentPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">New Deployment</button>
        <input v-model="search" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
      </div>
      <div v-if="message" class="float-start flex-row rounded m-2" :class="messageClass">
        <p> {{ message }}</p>
      </div>
      <PopupForm ref="newDeploymentForm" title="New Deployment" @form-submitted="createNewDeployment">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Url</label>
            <input id="name" v-model="create_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>


        <!-- # wp_deployment = {
    #     'url': 'test.nordkat.com',
    #     'repository': 'git@github.com:nordkat/gesmobles-web.git', # use with ssh only
    #     'branch': 'main',
    #     'backup_path': '/Users/albert/nordkat/gesmobles-migrate-20240826082632.sql',
    #     'type': 'wp', # wp, laravel, fastapi
    #     'env': 'prd' # dev, prod
    # } -->
        <div class="mb-4">
          <label for="description" class="block text-sm font-medium text-gray-700 float-start">Repository</label>
          <input id="description" v-model="create_form.description" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="description" class="block text-sm font-medium text-gray-700 float-start">Branch</label>
          <input id="description" v-model="create_form.description" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="description" class="block text-sm font-medium text-gray-700 float-start">Backup Path (Optional)</label>
          <input id="description" v-model="create_form.description" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="description" class="block text-sm font-medium text-gray-700 float-start">Technology</label>
          <select id="backup_plan" v-model="create_form.backup_plan_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option value="laravel">Laravel</option>
            <option value="wordpress">Wordpress</option>
            <option value="fastapi_vue">FastAPI+VueJS</option>
            <option value="vue">VueJS</option>
            <option value="fastapi">FastAPI</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="description" class="block text-sm font-medium text-gray-700 float-start">Env</label>
          <select id="backup_plan" v-model="create_form.backup_plan_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option value="dev">Dev</option>
            <option value="prod">Prod</option>
          </select>
        </div>

      </PopupForm>
      <PopupForm ref="editDeploymentForm" title="Edit Deployment" @form-submitted="editDeployment(edit_form.id)">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="edit_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="description" class="block text-sm font-medium text-gray-700 float-start">Description</label>
          <input id="description" v-model="edit_form.description" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="deleteDeploymentForm" title="Delete Deployment" @form-submitted="delDeployment(delete_id)">
        <div class="mb-4">
          <p class="block text-sm font-medium text-gray-700 float-start">Are you sure do you want to delete it?</p>
        </div>
      </PopupForm>
    </div>
  </ProjectLayout>
</template>
  
<script>
import ProjectLayout from '@/layouts/ProjectLayout'
import PopupForm from '@/components/PopupForm.vue';
import { getDeployments, createDeployment, updateDeployment, deleteDeployment } from '../services/api';
  
export default {
  components: {
    ProjectLayout,
    PopupForm,
  },

  data() {
    return {
      create_form: {
        name: '',
        description: ''
      },
      edit_form: {
        id: '',
        name: '',
        description: ''
      },
      deployments: [],
      message: '',
      messageClass: '',
      search: '',
      delete_id: '',
    };
  },
  async mounted() {
    try {
      this.deployments = await getDeployments().then(res => res.data);
    }
    catch {
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  },

  methods: {
    showNewDeploymentPopup() {
      this.$refs.newDeploymentForm.openForm();
    },
    showDeleteDeploymentPopup(id) {
      this.delete_id = id
      this.$refs.deleteDeploymentForm.openForm();
    },
    showEditDeploymentPopup(deployment) {
      this.edit_form.id = deployment.id
      this.edit_form.name = deployment.name
      this.edit_form.description = deployment.description
      this.$refs.editDeploymentForm.openForm();
    },
    async createNewDeployment() {
      try{
        const response = await createDeployment(this.create_form).then(res => res.data);
        if (response && response.message === 'deployments created successfully') {
          
          this.deployments = await getDeployments().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Deployment created successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't create the deployment"
          setTimeout(() => this.message = '', 2000);
        }
        this.create_form = {
          name: '',
          description: ''
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't create the deployment"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async editDeployment(id) {
      try{
        const response = await updateDeployment(id, this.edit_form).then(res => res.data);
        if (response && response.message.includes('edited successfully')) {
          this.deployments = await getDeployments().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Deployment edited successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't edit the deployment"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't edit the deployment"
        setTimeout(() => this.message = '', 2000);
      }
    },

    async delDeployment(id) {
      try{
        const response = await deleteDeployment(id).then(res => res.data);
        if (response && response.message.includes('deleted successfully')) {
          this.edit_form = {
            name: '',
            description: ''
          }
          this.deployments = await getDeployments().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Deployment deleted successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't delete the deployment"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't delete the deployment"
        setTimeout(() => this.message = '', 2000);
      }
    },
  },
  computed: {
    filteredDeployment(){
      return this.deployments.filter(deployment => {
        return deployment.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
      })
    }
  }
  
};
</script>
  